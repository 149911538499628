import logo from './logo.png';
import policeIcon from './police-icon.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>罗讷河丘和罗讷河谷法定原产地名称葡萄酒跨行业协会（法国）上海代表处</p>
      </header>

      <footer className="corporate">
        <div className="viewport">
          <div className="icp-copyright-container">
            <div>
              <p>
                <a target="_blank" href="https://beian.miit.gov.cn/">沪ICP备2023017979号-1</a>
              </p>
            </div>
            <div className='icp'>
              <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102007579">
                <img src={policeIcon} />
                <p>沪公⽹安备 31010102007579号</p>
              </a>
            </div>
            <div className="copyright">
              <p>Copyright 2023 © Interhone. All rights Reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
